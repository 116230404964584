<template>
  <div class="all_main_wrap">
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to=""
            @click.native="$router.go(-1)"
            >资讯管理 /
          </router-link>
          <span class="crumbs_item crumbs_last" v-if="!id">{{
            "新建"
          }}</span>
          <span class="crumbs_item crumbs_last" v-else>{{
            "编辑"
          }}</span>
        </span>
      </div>
    </div>
    <div class="all_content_box">
      <div class="remark" v-if="remark">注意：{{ remark }}</div>
      <a-form ref="ruleForm" :form="form" @submit="handleSubmit">
        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name all_required">标题：</span>
            <div class="right_Div">
              <a-input
                autocomplete="off"
                class="all_input"
                placeholder="请输入标题"
                v-decorator="[
                  'articleTitle',
                  { rules: [{ required: true, message: '请输入标题' }] },
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">资讯封面图：</span>
            <div class="right_Div required">
              <span class="ant-upload-picture-card-wrapper" v-if="banner">
                <div
                  class="
                    ant-upload
                    ant-upload-select
                    ant-upload-select-picture-card
                  "
                >
                  <span role="button" tabindex="0" class="ant-upload">
                    <div class="pictures">
                      <img
                        style="max-height: 100px; max-width: 100px"
                        :src="banner"
                        alt="avatar"
                      />
                      <div class="icon">
                        <a
                          :href="banner"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <a-icon type="eye" class="i" />
                        </a>
                        <a-icon
                          @click="banner = ''"
                          type="delete"
                          class="i"
                        />
                      </div>
                    </div>
                  </span>
                </div>
              </span>
              <a-upload
                v-else
                name="app"
                list-type="picture-card"
                :showUploadList="false"
                accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
                :beforeUpload="beforeUpload"
                :customRequest="customRequest"
              >
                <!-- <img
                  v-if="banner"
                  :src="banner"
                  style="max-width: 180px"
                  alt="avatar"
                /> -->
                <div>
                  <a-icon :type="loading ? 'loading' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
          </div>
        </a-form-item>

        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">概述/摘要：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入摘要"
                v-decorator="[
                  'overview'
                ]"
              />
            </div>
          </div>
        </a-form-item>
        <a-form-item v-else>
          <div class="L_R_inner">
            <span class="all_left_name all_required">领取成功文案：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入领取成功文案"
                v-decorator="[
                  'overview',
                  { rules: [{ required: true, message: '请输入领取成功文案' }] }
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item>
          <div class="L_R_inner">
            <span class="all_left_name" :class="{all_required: code == 'CouponActivity'}">app详情内容：</span>
            <UE
              class="editor"
              editorId="introduceEditor2"
              :initContent="mobileContent"
              @input="getmobileContent"
            />
            <!-- <QuillEditor class="editor" :initContent="mobileContent" @setInput="getmobileContent" /> -->
          </div>
        </a-form-item>

        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">pc详情内容：</span>
            <!-- <mavon-editor
              ref="md"
              v-model="content"
              :aria-hidden="true"
              @imgAdd="imgAdd"
              :xssOptions="{
                whiteList: {
                  iframe: ['src', 'height', 'width'],
                  div: ['style', 'height', 'width', 'align'],
                },
              }"
            >
              <template v-slot:left-toolbar-after>
                <button
                  type="button"
                  class="op-icon fa markdown-upload iconfont iconupload"
                  aria-hidden="true"
                  @click="uploadFile"
                > -->
                  <!-- 这里用的是element-ui给出的图标 -->
                 <!-- <a-icon title="视频上传" v-if="!mdUploadLoading" type="play-square" />
                  <a-icon title="视频上传中" v-if="mdUploadLoading" type="loading" />
                </button>
              </template>
            </mavon-editor> -->
            <!-- 在这里放一个隐藏的input，用来选择文件 -->
            <!-- <input ref="uploadInput" style="display: none" type="file" @change="uploadFileChange"> -->
            <UE
              class="editor"
              editorId="introduceEditor1"
              :initContent="pcContent"
              @input="getpcContent"
            />
            <!-- <QuillEditor class="editor" :initContent="pcContent" @setInput="getpcContent" /> -->
          </div>
        </a-form-item>
        
        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">视频：</span>
            <div class="right_Div">
              <a-upload
                name="videoLink"
                :showUploadList="false"
                :customRequest="videocustomRequest"
              >
                <a-button>
                  <template v-if="uploadLoading">
                    <a-icon type="loading" />
                    <span>上传中 {{(percent*100).toFixed(0)}}%</span>
                  </template>
                  <template v-else>
                    <a-icon type="upload" />
                    <span>选择视频文件</span>
                  </template>
                </a-button>
              </a-upload>
              <p v-if="videoLink">
                <span>上传成功：</span>
                <span>{{videoLink}}</span>
              </p>
            </div>
          </div>
        </a-form-item>

        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">标签：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入标签（多个请以','分割）"
                v-decorator="['label']"
              />
            </div>
          </div>
        </a-form-item>
        <a-form-item v-else>
          <div class="L_R_inner">
            <span class="all_left_name all_required">优惠券id：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入优惠券id（多个请以','分割）"
                v-decorator="[
                  'label',
                  { rules: [{ required: true, message: '请输入优惠券id' }] }
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">作者：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入作者"
                v-decorator="['author', { rules: [{ required: false }] }]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">来源：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入来源"
                :maxLength="10"
                v-decorator="[
                  'source'
                ]"
              />
            </div>
          </div>
        </a-form-item>
        <a-form-item v-else>
          <div class="L_R_inner">
            <span class="all_left_name all_required">活动页主题颜色：</span>
            <div class="right_Div">
              <a-input
                class="all_input"
                autocomplete="off"
                placeholder="请输入活动页主题颜色"
                v-decorator="[
                  'source',
                  { rules: [{ required: true, message: '请输入活动页主题颜色' }] }
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <a-form-item v-show="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name all_required">所属分类：</span>
            <div class="right_Div">
              <a-cascader
                class="cascader-input"
                :options="options"
                placeholder="请选择所属分类"
                change-on-select
                @change="onChange"
                v-decorator="[
                  'columnIdList',
                  { rules: [{ required: true, message: '请选择所属分类' }] },
                ]"
              />
                <!-- :load-data="loadData" -->
            </div>
          </div>
        </a-form-item>
        
        <a-form-item v-if="code != 'CouponActivity'">
          <div class="L_R_inner">
            <span class="all_left_name">点击量：</span>
            <div class="right_Div">
              <a-input-number
                :min=0
                class="all_input"
                autocomplete="off"
                placeholder="请输入点击量"
                v-decorator="[
                  'pageviews'
                ]"
              />
            </div>
          </div>
        </a-form-item>

        <div class="button">
          <a-button type="primary" class="btn" html-type="submit" :loading="submitLoading"
            >保存</a-button
          >
          <a-button class="all_boder_btn btn" @click="$router.go(-1)"
            >取消</a-button
          >
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import UE from "@/components/UE.vue";
import QuillEditor from "@/components/QuillEditor/index.vue";
// import markDownMixin from './markDownMixin'
export default {
  // 可用组件的哈希表
  components: { UE, QuillEditor }, // 接收传值
  // mixins: [markDownMixin],
  props: {}, // 数据对象
  data() {
    return {
      code: '', // 分类编码
      remark: '', // 分类备注
      type: '',
      loading: false,
      submitLoading: false, //提交中
      state: 1, //1新增 2编辑
      formLayout: "horizontal",
      form: this.$form.createForm(this, { name: "coordinated" }),
      detailData: {}, //详情
      id: "", //资讯id
      userType: "", //资讯分类
      // likeList: [], //偏好设置
      newTypeList: [], //资讯分类

      banner: "", // 封面图
      content: "", // 详情
      ueInitText: "", // 详情-回显用
      pcContent: '', // pc详情-回显用
      mobileContent: '', // app详情-回显用

      // 分类选项数据
      options: [],

      // 视频上传
      percent: 0,
      uploadLoading: false,
      videoLink: '',

      // 富文本视频上传
      mdUploadLoading: false
    };
  }, // 事件处理器
  methods: {
    // 富文本上传视频
    // 这是我们自定义的按钮触发的方法，这里也可以在自定义其他功能时做一些其他操作。
    uploadFile() {
      // 通过ref找到隐藏的input标签，触发它的点击方法
      this.$refs.uploadInput.click()
    },
    // 监听input获取文件的状态
    uploadFileChange(e) {
      this.mdUploadLoading = true;
      // 获取到input选取的文件
      const file = e.target.files[0]
      // 创建form格式的数据，将文件放入form中，logo是与后台定义的字段
      const formdata = new FormData()
      formdata.append('upfile', file)
      this.$ajax({
        url: '/hxclass-management/uEditor/config?action=uploadvideo&encode=utf-8',
        method: 'post',
        params: formdata
      }).then(res => {
        // 这里获取到的是mavon编辑器实例，上面挂载着很多方法
        // 将文件名与文件路径插入当前光标位置，这是mavon-editor 内置的方法
        let linkFrameStart = `<div align=center width=100%><iframe height=500 width=100% src='`
        let linkFrameEnd = "' frameborder=0 'allowfullscreen'></iframe></div>"
        this.$refs.md.d_value += linkFrameStart + '\n' + res.url + '\n' + linkFrameEnd;
        this.mdUploadLoading = false
      }).catch(()=>{
        this.mdUploadLoading = false
        this.$message.error('视频上传失败')
      })
    },

    // 重置文件上传 - 单文件
    videocustomRequest(fileData){
      this.percent = 0
      this.uploadLoading = true
      // this.$upload_video({
      //   file: fileData.file,
      //   progress: (e)=>{
      //     this.$set(this, 'percent', e.percent)
      //   },
      //   success: (e)=>{
      //     this.videoLink = e
      //     this.uploadLoading = false
      //   }
      // })
      const bucket = 'user/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallbackVideo,(url, fileName) => {
        // 上传完成
        if (url) {
          this.videoLink = url
        } else {
          this.$message.error("上传失败");
        }
        this.uploadLoading = false
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallbackVideo(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
       this.$set(this, 'percent', progress/100)
    },
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    onChange(value) {
      this.options.some(item=>{
        if(item.value == value[0]) {
          this.code = item.code;
        }
      })
    },
    loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;

      // load options lazily
      setTimeout(() => {
        targetOption.loading = false;
        targetOption.children = [
          {
            label: `${targetOption.label} Dynamic 1`,
            value: 'dynamic1',
          },
          {
            label: `${targetOption.label} Dynamic 2`,
            value: 'dynamic2',
          },
        ];
        this.options = [...this.options];
      }, 1000);
    },

    // 资讯分类
    async getNewType() {
      await this.$ajax({
        url: "/hxclass-management/news/type/" + this.type,
        method: "get",
      }).then((res) => {
        this.newTypeList = res.data;
      });
    },
    // 资讯详情
    async getData() {
      await this.$ajax({
        url: "/hxclass-management/Article/detail/" + this.id,
        method: "get",
      }).then((res) => {
        let detailData = res.data;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            articleTitle: detailData.articleTitle, //标题
            overview: detailData.overview, //摘要
            author: detailData.author, //作者
            source: detailData.source, //来源
            pageviews: detailData.pageviews, //浏览量
            newsTypeId: detailData.newsTypeId, //分类
            label: detailData.label // 标签
          });
        });
        this.videoLink = detailData.videoLink // 视频链接
        this.pcContent = detailData.pcContent; // pc内容
        this.mobileContent = detailData.mobileContent; // app内容
        this.banner = res.data.banner; // 封面图
      });
    },

    // 根据栏目id获取其父类
    getPidList(e) {
      this.$ajax({
        url: "/hxclass-management/ArticleColumn/parent/" + e,
        method: "get",
      }).then((res) => {
        if(res.code == 200 && res.success) {
          this.mapObj(res.data, []) // 回调回显分类
        }
      })
    },
    // 回调获取分类集合
    mapObj(e, arr) {
      arr.unshift(e.id)
      if(e.columnParentVO) {
        this.mapObj(e.columnParentVO, arr);
      }else{
        this.form.setFieldsValue({
          columnIdList: arr, // 分类默认值
        });
      }
    },

    handleSubmit(e) {
      if(this.uploadLoading) {
        return this.$message.warning('视频正在上传，请稍等...')
      }
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // if(!this.pcContent) {
          //   return this.$message.warning('请填写pc详情内容！')
          // }
          if(this.code == 'CouponActivity' && !this.mobileContent) {
            return this.$message.warning('请填写app详情内容！')
          }
          // 偏好设置转为字符串
          // values.likeList = values.likeList ? values.likeList.join(",") : ''
          values.banner = this.banner;
          values.pcContent = this.pcContent;
          values.mobileContent = this.mobileContent;
          values.videoLink = this.videoLink;
          values.columnId = values.columnIdList.pop();
          values.columnIdList.push(values.columnId)
          if(!this.id) { // 新建
            values.isEnable = 0;
          }else{
            values.id = this.id;
          }

          this.onSubmit(values);
        } else {
          this.$message.warning("请检查必填项是否填写完整！");
        }
      });
    },
    // 提交
    onSubmit(value) {
      // 提交中
      if (!!this.submitLoading) {
        return
      }
      this.submitLoading = true //提交中

      let params = { ...value };
      this.$ajax({
        url: "/hxclass-management/Article/update",
        method: 'post',
        params,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("提交成功");
          this.$router.replace('/admin/ContentConfig/informationManage');
        }
        this.submitLoading = false //提交完毕
      });
    },
    // 上传前钩子
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },

    // 文件上传
    customRequest(fileData) {
      const fileName = fileData.filename // 名称前缀
      let picture = fileName + 'Img'

      this.loading = true;

      const bucket = 'ueditor/article/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.banner = url
        } else {
          this.$message.error("上传失败");
        }
        this.loading = false;
      });
    },
    
    // 详情
    getpcContent(e) {
      this.$set(this, "pcContent", e);
    },
    getmobileContent(e) {
      this.$set(this, "mobileContent", e);
    },

    // 资讯分类选择
    changeNewType(e) {
      // console.log(e);
    },
    // 获取分类详情取分类备注信息
    getColumnDetail(id){
      this.$ajax({
        url: '/hxclass-management/ArticleColumn/detail/' + id
      }).then(res=>{
        if(res.code == 200 && res.success){
          this.remark = res.data.remark;
        }
      }).catch(err=>{
        
      })
    },
    // 获取分类列表
    async getList(id,obj) {
      await this.$ajax({
        url: '/hxclass-management/ArticleColumn/list/' + id
      }).then(res=>{
        if(res.code == 200 && res.success){
          let arr = res.data.map(item=>{
            return {
              label: item.columnTitle,
              value: item.id,
              isLeaf: item.subset == 0,
              code: item.columnCode
            }
          })
          arr.map(item=>{
            if(!item.isLeaf) {
              this.getList(item.value,item)
            }
          })
          if(id == 0) {
            this.options = arr;
          }else{
            this.$set(obj,'children',arr)
          }
        }
      }).catch(err=>{
        
      })
    }
  }, // 生命周期-实例创建完成后调用
  created() {
    if(!this.$route.query.typeId){
      return this.$message.error('您暂未选择栏目分类！')
    }

    // 栏目分类
    this.getList(0);

    let query = this.$route.query;
    this.columnId = query.typeId
    this.code = query.code
    this.getColumnDetail(this.columnId)
    this.getPidList(this.columnId) // 获取父级节点集合-回显所属分类

    // 编辑
    if (query.id) {
      this.id = query.id;
      this.getData();
    }
  }, // 生命周期-实例挂载后调用
  mounted() {}, // 生命周期-实例销毁离开后调用
  destroyed() {}, // 计算属性监听
  computed: {}, // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-form-item {
  margin: 24px;
}
/deep/.all_content_box .all_input {
  height: 37px;
}
/deep/.ant-input-number-input {
  height: 37px;
  padding: 0;
}
/deep/.ant-form-explain {
  margin-left: 160px;
}
/deep/.ant-checkbox-wrapper {
  margin-left: 0;
  margin-right: 40px;
}
.ant-upload-picture-card-wrapper {
  width: auto;
}
.all_main_wrap {
  a {
    color: #333333;
  }

  .title-blod {
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
  }

  .all_content_box {
    .L_R_inner {
      // margin: 24px;
      .greytext {
        font-size: 14px;
        color: #888;
      }

      .all_left_name {
        width: 140px;
        text-align: right;
      }
      .all_left_info {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }

      .vue-ueditor-wrap[data-v-1e1388a0] {
        margin-left: 20px;
        width: 600px;
        margin-top: 10px;
      }

      .right_Div {
        .inneer_img {
          width: 100px;
          margin-right: 8px;
          margin-bottom: 8px;
        }
        .cascader-input,
        .ant-select,
        .all_input {
          width: 337px;
        }
        /deep/.ant-input {
          height: 37px;
        }
      }
      .editor{
        min-width: 1200px;
      }
    }
  }

  .button {
    .btn {
      margin-left: 20px;
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  cursor: pointer;
  transition: border-color 0.3s ease;
}
.pictures .icon {
  transition: all 0.3s;
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.pictures:hover {
  .icon {
    opacity: 1;
  }
}
.pictures {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
}
.remark{
  padding-top: 50px;
  padding-left: 50px;
  color: red;
}
</style>
