	// 创建 quill-title.js
	// 给工具栏设置title
	const titleConfig = {
	  'ql-bold': '加粗',
	  'ql-font': '字体',
	  'ql-code': '插入代码',
	  'ql-italic': '斜体',
	  'ql-link': '添加链接',
	  'ql-color': '字体颜色',
	  'ql-background': '背景颜色',
	  'ql-size': '字体大小',
	  'ql-strike': '删除线',
	  'ql-script': '上标/下标',
	  'ql-underline': '下划线',
	  'ql-blockquote': '引用',
	  'ql-header': '标题',
	  'ql-indent': '缩进',
	  'ql-list': '列表',
	  'ql-align': '文本对齐',
	  'ql-direction': '文本方向',
	  'ql-code-block': '代码块',
	  'ql-formula': '公式',
	  'ql-image': '图片',
	  'ql-video': '视频',
	  'ql-clean': '清除字体样式'
	}
	export function setQuillTitle () {
	  const oToolBar = document.querySelector('.ql-toolbar')
	  const aButton = oToolBar.querySelectorAll('button')
	  const aSelect = oToolBar.querySelectorAll('select')
	  aButton.forEach(function (item) {
	    if (item.className === 'ql-script') {
	      item.value === 'sub' ? item.title = '下标' : item.title = '上标'
	    } else if (item.className === 'ql-indent') {
	      item.value === '+1' ? item.title = '向右缩进' : item.title = '向左缩进'
	    } else {
	      item.title = titleConfig[item.className]
	    }
	  })
	  // 字体颜色和字体背景特殊处理，两个在相同的盒子
	  aSelect.forEach(function (item) {
	    if (item.className.indexOf('ql-background') > -1) {
	      item.previousSibling.title = titleConfig['ql-background']
	    } else if (item.className.indexOf('ql-color') > -1) {
	      item.previousSibling.title = titleConfig['ql-color']
	    } else {
	      item.parentNode.title = titleConfig[item.className]
	    }
	  })
	}
