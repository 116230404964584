import { Quill } from 'vue-quill-editor'

var sizes = [false, "16px", "18px", "20px", "22px", "26px", "28px", "30px"];
var Size = Quill.import("formats/size");
Size.whitelist = sizes;
export default {
  toolbarOptions: [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
    ["blockquote", "code-block"], // 引用  代码块
    ["clean"], // 清除文本格式
    ["link", "image", "video"], // 视频
    [{ 'direction': 'rtl' }],  // 文本方向
    [{ 'code-block': true }], // 代码块
    [{ 'script': 'sub' }, { 'script': 'super' }],      // 下标，上标
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
    [{ indent: "-1" }, { indent: "+1" }], // 缩进
    [{ size: sizes }], // 字体大小
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
    [{ align: [] }], // 对齐方式
  ]
}