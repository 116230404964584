// quill-video.js
import { Quill } from "vue-quill-editor";
// 源码中是import直接倒入，这里要用Quill.import引入
const BlockEmbed = Quill.import('blots/block/embed')
const Link = Quill.import('formats/link')
const ATTRIBUTES = ['height', 'width']
class Video extends BlockEmbed {
	static create(value) {
		let node = super.create()
		// 添加video标签所需的属性
		node.setAttribute('controls', 'controls')
		node.setAttribute('playsinline', 'true')
		node.setAttribute('webkit-playsinline', 'true')
		node.setAttribute('type', 'video/mp4')
		// poster 属性指定视频下载时显示的图像，或者在用户点击播放按钮前显示的图像。
		node.setAttribute('poster', value.poster)
		node.setAttribute('src', this.sanitize(value.url))
		return node
	}
	static formats(domNode) {
		return ATTRIBUTES.reduce((formats, attribute) => {
			if (domNode.hasAttribute(attribute)) {
				formats[attribute] = domNode.getAttribute(attribute)
			}
			return formats
		}, {})
	}
	static sanitize(url) {
		return Link.sanitize(url)
	}
	static value(domNode) {
		// 设置自定义的属性值
		return {
			url: domNode.getAttribute('src'),
			poster: domNode.getAttribute('poster'),
		}
	}
	format(name, value) {
		if (ATTRIBUTES.indexOf(name) > -1) {
			if (value) {
				this.domNode.setAttribute(name, value)
			} else {
				this.domNode.removeAttribute(name)
			}
		} else {
			super.format(name, value)
		}
	}
	html() {
		const { video } = this.value()
		return `<a href="${video}" rel="external nofollow"  rel="external nofollow" >${video}</a>`
	}
}
Video.blotName = 'video' // 这里不用改，不用iframe，直接替换掉原来，如果需要也可以保留原来的，这里用个新的blot
Video.className = 'ql-video'  // 可添加样式，看实际使用需要
Video.tagName = 'video' // 用video标签替换iframe
export default Video
