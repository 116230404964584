<template>
  <div class="editor-wrap">
    <quill-editor
      :content="initContent"
      :options="editorOptions"
      class="ql-snow ql-editor"
      ref="myQuillEditor"
      @change="onEditorChange($event)"
    >
    </quill-editor>
    <a-upload
      class="avatar-uploader"
      accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
      :multiple="true"
      :showUploadList="false"
      :beforeUpload="beforeUploadImage"
      :customRequest="customRequestImage"
      >
    </a-upload>
    <a-modal v-model="videoFormShow" :closable="true" title="视频上传" @ok="setUploadVideo">
      <a-tabs v-model="tabIndex">
        <a-tab-pane :key="1" tab="添加视频链接">
          <a-input v-model="videoUrl" placeholder="请输入视频链接，如：http://xxxx.mp4" />
        </a-tab-pane>
        <a-tab-pane :key="2" tab="本地视频上传">
          <div>
            <a-upload-dragger
              :file-list="videoFileList"
              :showUploadList="true"
              accept=".flv,.mov,.mp4,.avi,.wmv,.mp4v" 
              :multiple="true"
              @preview="handlePreview"
              :remove="handleRemoveVideo"
              :beforeUpload="beforeUploadVideo"
              :customRequest="customRequestVideo"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="cloud-upload" />
              </p>
              <p class="ant-upload-text">将文件拖到此处，或<a>点击上传</a></p>
            </a-upload-dragger>
          </div>
        </a-tab-pane>
      </a-tabs>
    </a-modal>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

/* 依赖导入 */
import { quillEditor,Quill } from 'vue-quill-editor' // 导入富文本编辑器
import { ImageDrop } from 'quill-image-drop-module';  // 图片拖拽
import ImageResize from 'quill-image-resize-module';  // 改变图片大小

/* 配置项 */
import Video from "./quill-video"; 
import setQuillOption from "./quill-option.js";  // 工具栏配置项
import { setQuillTitle } from "./quill-title.js";

/* 插件注册 */
Quill.register('modules/imageDrop', ImageDrop);
Quill.register('modules/imageResize', ImageResize);
Quill.register(Video, true);

import { uuid } from "@/unit/fun.js";

export default {
  // 可用组件的哈希表
  components: { quillEditor },
  // 接收传值
  props: {
    initContent: {
      type: String,
      default: ''
    }
  },
  // 数据对象
  data() {
    let _self = this;
    return {
      tabIndex:1,
      videoUrl:'',
      range: 0, // 光标位置
      videoFileList:[], // 视频上传列表
      videoFormShow: false, // 视频上传弹窗
      editorOptions: {
        placeholder: "",
        theme: "snow", // or 'bubble'
        modules: {
          imageDrop: true,      // 图片拖拽
          imageResize: {        // 放大缩小
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white"
            },
            modules: ["Resize", "DisplaySize", "Toolbar"]
          },
          toolbar: {
            container: setQuillOption.toolbarOptions, // 工具栏
            handlers: {
              video: () => {
                _self.videoFormShow = true;
                _self.range = _self.$refs.myQuillEditor.quill.selection.savedRange.index;
              },
              image: (value) => {
                _self.range = _self.$refs.myQuillEditor.quill.selection.savedRange.index;
                document.querySelector(".avatar-uploader input").click();
              },
            },
          },
        },
      },
    };
  },
  // 事件处理器
  methods: {
    // 获取富文本内容
    onEditorChange({ quill, html, text }) {
      // console.log(html);
      this.$emit('setInput', html)
    },

    setUploadVideo(){
      if(this.tabIndex == 1){
        if(!this.videoUrl){
          this.$message.warning('请填写视频链接地址');
          return
        }
        this.insertVideoLink(this.videoUrl,'','video')
        this.videoUrl = ''
      } else if(this.tabIndex == 2){
        if(!this.videoFileList.length){
          this.$message.warning('请上传视频');
          return
        }
        this.videoFileList.forEach(element => {
          this.insertVideoLink(element.url,'','video')
        });
        this.videoFileList = []
      }
      this.videoFormShow = false
    },

    // 预览视频
    handlePreview(e){
      window.open(e.url);
    },
    // 删除视频
    handleRemoveVideo(e,r){
      this.videoFileList = this.videoFileList.filter(item => item.uid !== e.uid);
    },

    // 视频上传前校验
    beforeUploadVideo(file){
      if (!this.$regular.videoReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.videoReg.msg);
        return false;
      }
    },

    // 视频上传
    customRequestVideo(fileData){
      const bucket = 'ueditor/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        console.log(fileData.file.name);
        // 上传完成
        if (url) {
          this.videoFileList.push({
            uid: uuid(),
            name: fileData.file.name,
            status: 'done',
            url: url,
          })
          console.log(this.videoFileList);
          // this.insertVideoLink(url,'','img')
        } else {
          this.$message.error("上传失败");
        }
      })
    },
    
    // 图片上传
    customRequestImage(fileData){
      const bucket = 'ueditor/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.insertVideoLink(url,'','img')
        } else {
          this.$message.error("上传失败");
        }
      })
    },
    // 图片上传前格式校验
    beforeUploadImage(file){
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
    },
    
    // 视频和图片链接在编辑器渲染
    insertVideoLink(videoLink, poster, type) {
      /**
       * @param {String} videoLink 文件在线地址
       * @param {String} poster 视频封面图
       * @param {String} type 文件类型 img图片  video视频
      */
      if (!videoLink) return this.$message.error("视频地址不能为空！");
      let quill = this.$refs["myQuillEditor"].quill;

      // 获取富文本
      let range = quill.getSelection();
      // 获取光标位置：当编辑器中没有输入文本时，这里获取到的 range 为 null
      // 这里上传视频的时候光标会消失所以拿不到光标位置，但是总不能每次上传都放在第一个位置啊，所以对光标位置手动记录了一下 就是 handlers 里面配置的点击方法每个方法里有记录
      let index = range ? range.index : this.range;
      // 没有视频默认封面时，设置默认视频封面图片
      const img = poster
        ? poster
        : "";
      if (type !== "img") {  // 视频
        // 在光标所在位置 插入视频
        quill.insertEmbed(index, "video", {
          url: videoLink,
          // poster: img,
        });
      } else {  // 图片
        quill.insertEmbed(index, "image", videoLink);
      }
      // 调整光标到最后
      quill.setSelection(index + 1);
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setQuillTitle();
    })
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.editor-wrap{
  margin-top: -20px;
}
</style>
